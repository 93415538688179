import NewBookForm from "../components/books/NewBookForm";

function NewBook() {
  return (
    <section>
      <NewBookForm />
    </section>
  );
}

export default NewBook;
