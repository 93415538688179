import BookList from "../components/books/BookList";

function AllBooks() {
  return (
    <section>
      <BookList />
    </section>
  );
}

export default AllBooks;
